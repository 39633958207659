import { BaseService } from "../base.service";
import { ErrorWrapper, ResponseWrapper } from "../util";

export class TaxonomyService extends BaseService {
    static get entity() {
        return "taxonomy"
    }

    static apiClient(options) {
        const baseURL = process.env.VUE_APP_API_URL;
        return this.initApiClient({ baseURL, ...options });
    }

    static async runKeyword(params = {}) {
        try {
            const response = await this.apiClient().get('taxonomy/run/keyword', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    static async runUrl(params = {}) {
        try {
            const response = await this.apiClient().get('taxonomy/run/url', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    // static async runUrls(params = {}) {
    //     try {
    //         const response = await this.apiClient().get('taxonomy/run/urls', { params });
    //         return new ResponseWrapper(response, response.data);
    //     } catch (error) {
    //         const message = error.response.data ? error.response.data.error : error.response.statusText;
    //         throw new ErrorWrapper(error, message);
    //     }
    // }

    static async runUrls(data = {}) {
        try {
            const response = await this.apiClient({ auth: true }).post('taxonomy/run/urls', { data });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            throw new ErrorWrapper(error);
        }
    }

    static async getLocations(params = {}) {
        try {
            const response = await this.apiClient().get('taxonomy/locations', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getKeywords(params = {}) {
        try {
            const response = await this.apiClient().get('taxonomy/keywords', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getKeyword(params = {}) {
        try {
            const response = await this.apiClient().get('taxonomy/keyword', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    static async competitors(params = {}) {
        try {
            const response = await this.apiClient().get('taxonomy/competitors', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    // static async competitorsMissing(params = {}) {
    //     try {
    //         const response = await this.apiClient().get('taxonomy/competitors/missing', { params });
    //         return new ResponseWrapper(response, response.data);
    //     } catch (error) {
    //         const message = error.response.data ? error.response.data.error : error.response.statusText;
    //         throw new ErrorWrapper(error, message);
    //     }
    // }

}