<template>
	<div>
		<!-- {{ locations }} -->
		<b-container fluid class="shadow mb-5">
			<h1 class="text-center my-4" style="min-height: 1.2em">
				<a
					:href="'https://www.google.com/search?q=' + keyword.text"
					class="text-decoration-none text-muted"
					>{{ keyword.text }}</a
				>
			</h1>
			<b-row class="mb-5">
				<b-col class="mb-1">
					<b-input-group>
						<b-form-input
							id="search-string"
							style="box-shadow: none"
							v-model="keyword.text"
							placeholder="Enter keyword ..."
							size="sm"
							squared
							trim
							description="keyword"
							autofocus
							type="text"
							@keydown.enter.native="runKeyword()"
						></b-form-input>
						<b-button-group>
							<b-dropdown
								variant="dark"
								size="sm"
								squared
								:text="
									'location: ' +
									keyword.location.name +
									' ' +
									'(' +
									keyword.location.code +
									')'
								"
								id="keyword-location"
								name="keyword.location"
								v-model="keyword.location"
							>
								<b-dropdown-item-button
									v-for="location in locations"
									:key="location.name"
									:value="location.country_iso_code"
									squared
									@click="keyword.location = location"
								>
									{{
										location.name + " " + "(" + location.country_iso_code + ")"
									}}
								</b-dropdown-item-button>
							</b-dropdown>

							<b-dropdown
								variant="dark"
								size="sm"
								squared
								v-model="keyword.language_code"
								:text="'language: ' + keyword.language_code"
							>
								<b-dropdown-item-button
									v-for="language_code in languages"
									:key="language_code"
									:value="language_code"
									squared
									@click="keyword.language_code = language_code"
								>
									{{ language_code }}
								</b-dropdown-item-button>
							</b-dropdown>

							<b-dropdown
								variant="dark"
								size="sm"
								squared
								v-model="keyword.device"
								:text="'device: ' + keyword.device"
							>
								<b-dropdown-item-button
									v-for="device in devices"
									:key="device"
									:value="device"
									squared
									@click="keyword.device = device"
								>
									{{ device }}
								</b-dropdown-item-button>
							</b-dropdown>
						</b-button-group>
						<b-button
							:pressed="false"
							style="box-shadow: none"
							active-class="active"
							size="sm"
							squared
							variant="danger"
							@click="runKeyword()"
							>Analyze</b-button
						>
					</b-input-group>
				</b-col>
			</b-row>

			<b-row class="pb-3">
				<b-col cols="2">
					<b-form-checkbox
						id="checkbox-1"
						v-model="keyword.forceUpdate"
						name="checkbox-1"
						value="accepted"
						unchecked-value="not_accepted"
						squared
					>
						Force update
					</b-form-checkbox>
				</b-col>
				<b-col>
					<FilterInput :filterInput="filterInput" />
				</b-col>
				<b-col v-if="!isBusy" class="mr-1">
					{{ "Data from: " + dataFrom }}
				</b-col>
			</b-row>

			<!-- spinner -->
			<div class="text-center mb-5" v-if="isBusy && spinner">
				<b-row type="grow" class="justify-content-md-center my-5 pb-5">
					<b-spinner
						variant="dark"
						style="width: 25rem; height: 25rem"
					></b-spinner>
				</b-row>
				<span class="sr-only">Analyzing "{{ keyword.text }}"</span>
			</div>
		</b-container>

		<b-container fluid v-if="!isBusy" class="shadow">
			<b-row class="mb-5 shadow">
				<b-col>
					<h2 class="text-center m-3">Google results</h2>
					<table class="table striped small hover responsive">
						<thead>
							<tr>
								<th
									style="height: 1.2em; padding: 0; border-top: 0"
									scope="col"
								>
									Position
								</th>
								<th
									style="height: 1.2em; padding: 0; border-top: 0"
									scope="col"
								>
									Title
								</th>
								<th
									style="height: 1.2em; padding: 0; border-top: 0"
									scope="col"
								>
									URL
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in dataforseoItems" :key="item.id">
								<td style="height: 1.2em; padding: 0; border-top: 0">
									{{ item.rank_group }}
								</td>
								<td style="height: 1.2em; padding: 0; border-top: 0">
									{{ item.title }}
								</td>
								<td style="height: 1.2em; padding: 0; border-top: 0">
									<a :href="item.url">{{ item.url }}</a>
								</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>

			<b-row v-if="!isBusy" class="mb-5 shadow">
				<h2 class="text-center my-4">Taxonomy</h2>
				<Chart
					:chartOptions="chartConceptsOptions"
					:series="seriesConcepts"
					:type.sync="chartConceptsOptions.chart.type"
					id="chart-concepts"
					fluid
				/>

				<Chart
					:chartOptions="chartCategoriesOptions"
					:series="seriesCategories"
					type="bar"
					id="chart-categories"
					fluid
				/>

				<Chart
					:chartOptions="chartEntitiesOptions"
					:series="seriesEntities"
					:type="chartEntitiesOptions.chart.type"
					id="chart-entities"
					fluid
				/>

				<Chart
					:chartOptions="chartKeywordsOptions"
					:series="seriesKeywords"
					:type="chartKeywordsOptions.chart.type"
					id="chart-keywords"
					fluid
				/>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { TaxonomyService } from "../services/taxonomy/taxonomy.service";
// import Search from "../components/Search.vue";
// import Sort from "../components/Sort.vue";
import FilterInput from "../components/FilterInput.vue";
import Chart from "../components/Chart.vue";

export default {
	name: "Taxonomy",
	components: {
		FilterInput,
		Chart,
	},
	data() {
		return {
			isBusy: true,
			spinner: false,
			dataFrom: "",
			keyword: {
				text: "",
				language_code: "de",
				device: "mobile",
				// location: ["2276", "Germany", "", "DE", "Country"],
				location: {
					code: 2276,
					name: "Germany",
					code_parent: "",
					country_iso_code: "DE",
					type: "Country",
				},

				forceUpdate: false,
			},
			languages: [
				"en",
				"de",
				"fa",
				"nl",
				"fr",
				"id",
				"it",
				// 'ja',
				"no",
				"pt",
				"ru",
				"es",
				"sv",
			],
			devices: ["desktop", "mobile"],
			locations: [],

			filterInput: {
				value: 10,
				min: 10,
				max: 50,
				step: 10,
				name: "Google top results",
			},

			//dataforseo
			dataforseoItems: [],

			//concepts
			seriesConcepts: [],
			chartConceptsOptions: {
				chart: {
					id: "concepts",
					type: "radar",
					toolbar: {
						autoSelected: "pan",
						show: true,
						export: {
							csv: {
								headerCategory: "concept",
								// headerValue: "value",
							},
						},
					},
				},
				plotOptions: {},
				dataLabels: {},
				xaxis: {},
			},

			//categories
			seriesCategories: [],
			chartCategoriesOptions: {
				chart: {
					id: "categories",
					type: "bar",
					toolbar: {
						autoSelected: "pan",
						show: true,
						export: {
							csv: {
								headerCategory: "category",
								// headerValue: "value",
							},
						},
					},
				},
				plotOptions: {},
				dataLabels: {},
				xaxis: {},
			},

			//entities
			seriesEntities: [],
			chartEntitiesOptions: {
				chart: {
					id: "entities",
					type: "bar",
					toolbar: {
						autoSelected: "pan",
						show: true,
						export: {
							csv: {
								headerCategory: "entity",
								// headerValue: "value",
							},
						},
					},
				},
				plotOptions: {},
				dataLabels: {},
				xaxis: {},
			},

			//keywords
			seriesKeywords: [],
			chartKeywordsOptions: {
				chart: {
					id: "keywords",
					type: "bar",
					toolbar: {
						autoSelected: "pan",
						show: true,
						export: {
							csv: {
								headerCategory: "keyword",
								// headerValue: "value",
							},
						},
					},
				},
				plotOptions: {},
				dataLabels: {},
				xaxis: {},
			},
		};
	},

	// watch: {
	// },

	computed: {
		sortOptions() {
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},

	async created() {
		const locations = await TaxonomyService.getLocations();
		this.locations = locations.data;

		if (
			this.$route.params.keyword &&
			this.$route.params.language_code &&
			this.$route.params.location_code &&
			this.$route.params.device
		) {
			this.keyword.text = this.$route.params.keyword;
			this.keyword.language_code = this.$route.params.language_code;
			this.keyword.location = this.locations.filter((obj) => {
				return obj.code == this.$route.params.location_code;
			})[0];
			this.keyword.device = this.$route.params.device;
			await this.runKeyword();

			//clear route
			let route = this.$route;
			if (Object.keys(route.query).includes(this.query)) {
				this.queryPush = { ...route.query };
				delete this.queryPush[this.query];
				this.$router.push({ path: "/taxonomy/keyword", query: this.queryPush });
			} else {
				this.queryPush = { [this.query]: this.value };
				this.$router.push({ path: "/taxonomy/keyword", query: this.queryPush });
			}
		}
	},

	// async created() {
	//   await this.getChartConceptsData();
	//   // this.locations = await this.getLocations();
	// },

	// async mounted() {
	//   await this.getChartConceptsData();
	//   // await this.getMoreItems();
	// },

	methods: {
		async runKeyword() {
			// console.log(this.keyword);
			if (this.keyword.text) {
				this.isBusy = true;
				this.spinner = true;
				const { data: data } = await TaxonomyService.runKeyword({
					keyword: this.keyword.text.toLowerCase(),
					language_code: this.keyword.language_code,
					location_code: this.keyword.location.code.toString(),
					device: this.keyword.device,
					serp_top_n: this.filterInput.value,
					forceUpdate: this.keyword.forceUpdate,
				});

				this.totalRowsConcepts = data.concepts.length;
				this.itemsConcepts = data.concepts;
				this.dataFrom = data.dataFrom;

				this.getChartConceptsData(data.concepts);
				this.getChartCategoriesData(data.categories);
				this.getChartEntitiesData(data.entities);
				this.getChartKeywordsData(data.keywords);
				this.dataforseoItems = data.dataforseoItems;

				this.keyword.forceUpdate = false;
				this.isBusy = false;
				this.spinner = false;
				console.log("data: ", data);

				return;
			}
		},

		// async updateData() {
		//   await this.run();
		// },

		getChartConceptsData(concepts) {
			concepts.sort((a, b) =>
				a.relevance_avg < b.relevance_avg
					? 1
					: b.relevance_avg < a.relevance_avg
					? -1
					: 0
			);
			concepts.sort((a, b) =>
				a.count < b.count ? 1 : b.count < a.count ? -1 : 0
			);
			const chartOptions = [];
			const counts = [];
			for (let i = 0; i < concepts.slice(0, 20).length; i++) {
				const item = concepts[i];
				chartOptions.push(item.concept + "|" + item.relevance_avg);
				counts.push(item.count);
			}
			this.chartConceptsOptions = {
				...this.chartConceptsOptions,
				...{
					xaxis: {
						categories: chartOptions,
						labels: {
							show: true,
							style: {
								colors: ["#a8a8a8"],
								fontSize: "10px",
								fontFamily: "Arial",
							},
						},
					},
					dataLabels: {
						enabled: true,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: true,
						},
					},
				},
			};

			this.seriesConcepts = [{ name: "count", data: counts }];
		},

		getChartCategoriesData(categories) {
			categories.sort((a, b) =>
				a.score_avg < b.score_avg ? 1 : b.score_avg < a.score_avg ? -1 : 0
			);
			categories.sort((a, b) =>
				a.count < b.count ? 1 : b.count < a.count ? -1 : 0
			);
			const chartOptions = [];
			const counts = [];
			for (let i = 0; i < categories.slice(0, 15).length; i++) {
				const item = categories[i];
				// chartOptions.push(item.label + "|" + item.score_avg);
				chartOptions.push(item.label);
				counts.push(item.count);
			}
			this.chartCategoriesOptions = {
				...this.chartCategoriesOptions,
				...{
					xaxis: {
						categories: chartOptions,
						labels: {
							show: true,
							style: {
								colors: ["#a8a8a8"],
								fontSize: "7px",
								fontFamily: "Arial",
							},
						},
					},
					dataLabels: {
						enabled: true,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: true,
						},
					},
				},
			};
			this.seriesCategories = [{ name: "count", data: counts }];
		},

		getChartEntitiesData(entities) {
			entities.sort((a, b) =>
				a.confidence_sum_avg < b.confidence_sum_avg
					? 1
					: b.confidence_sum_avg < a.confidence_sum_avg
					? -1
					: 0
			);
			entities.sort((a, b) =>
				a.relevance_sum_avg < b.relevance_sum_avg
					? 1
					: b.relevance_sum_avg < a.relevance_sum_avg
					? -1
					: 0
			);
			entities.sort((a, b) =>
				a.count < b.count ? 1 : b.count < a.count ? -1 : 0
			);

			const chartOptions = [];
			const counts = [];
			const count_sums = [];
			const count_sum_avgs = [];
			const relevance_sum_avgs = [];
			for (let i = 0; i < entities.slice(0, 20).length; i++) {
				const item = entities[i];
				chartOptions.push(item.text + "|" + item.type);
				counts.push(item.count);
				count_sums.push(item.count_sum);
				count_sum_avgs.push(item.count_sum_avg);
				relevance_sum_avgs.push(item.relevance_sum_avg);
			}
			this.chartEntitiesOptions = {
				...this.chartEntitiesOptions,
				...{
					xaxis: {
						categories: chartOptions,
						labels: {
							show: true,
							style: {
								colors: ["#a8a8a8"],
								fontSize: "10px",
								fontFamily: "Arial",
							},
						},
					},
					dataLabels: {
						enabled: true,
						style: {
							fontSize: "10px",
						},
					},
					plotOptions: {
						bar: {
							barHeight: "100%",
							borderRadius: 4,
							horizontal: true,
						},
					},
				},
			};
			this.seriesEntities = [
				{ name: "count", data: counts },
				{ name: "count_sum", data: count_sums },
				{ name: "count_sum_avg", data: count_sum_avgs },
				{ name: "relevance_sum_avgs", data: relevance_sum_avgs },
			];
		},

		getChartKeywordsData(keywords) {
			keywords.sort((a, b) =>
				a.relevance_sum_avg < b.relevance_sum_avg
					? 1
					: b.relevance_sum_avg < a.relevance_sum_avg
					? -1
					: 0
			);
			keywords.sort((a, b) =>
				a.count_sums < b.count_sums ? 1 : b.count_sums < a.count_sums ? -1 : 0
			);
			keywords.sort((a, b) =>
				a.count < b.count ? 1 : b.count < a.count ? -1 : 0
			);
			const chartOptions = [];
			const counts = [];
			const count_sums = [];
			const count_sum_avgs = [];
			const relevance_sum_avgs = [];
			for (let i = 0; i < keywords.slice(0, 15).length; i++) {
				const item = keywords[i];
				chartOptions.push(
					// item.text + "|" + item.relevance_sum_avg + "|" + item.sentiment_label
					// item.text.slice(0, 10)
					item.text
					// item.text.split(" ").join("\n")
				);
				counts.push(item.count);
				count_sums.push(item.count_sum);
				count_sum_avgs.push(item.count_sum_avg);
				relevance_sum_avgs.push(item.relevance_sum_avg);
			}
			this.chartKeywordsOptions = {
				...this.chartKeywordsOptions,
				...{
					xaxis: {
						categories: chartOptions,
						labels: {
							show: true,
							style: {
								colors: ["#a8a8a8"],
								fontSize: "10px",
								fontFamily: "Arial",
							},
						},
					},
					dataLabels: {
						enabled: true,
						style: {
							fontSize: "10px",
						},
					},
					plotOptions: {
						bar: {
							barHeight: "100%",
							borderRadius: 4,
							horizontal: true,
						},
					},
				},
			};
			this.seriesKeywords = [
				{ name: "count", data: counts },
				{ name: "count_sum", data: count_sums },
				{ name: "count_sum_avg", data: count_sum_avgs },
				{ name: "relevance_sum_avg", data: relevance_sum_avgs },
			];
		},
	},
};
</script>